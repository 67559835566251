body {
  background: linear-gradient(
    90deg,
    rgba(244, 217, 255, 0.66) 0.52%,
    rgba(255, 217, 242, 0.66) 24.02%,
    rgba(201, 211, 255, 0.66) 72.81%
  );
}

.PageWrapper {
  padding: 24px;
  width: 1100px;
  max-width: 100%;
  margin: auto;
}
.bold {
  font-weight: bold;
}
.Register_container {
  margin-left: 12px;
  margin-right: 12px;
}
.Register_wrapper {
  display: flex;
  background-color: white;
  border-radius: 12px;
  width: 800px;
  max-width: 100%;
  justify-content: center;
  margin: auto;
  margin-top: 120px;
  box-shadow: 2px 2px 12px #cfcfcf;

  .Register_left {
    flex-basis: 50%;
    padding: 48px 60px;
    border-right: 1px solid rgba(0, 0, 0, 0.08);
  }
  .Register_right {
    flex-basis: 50%;
    padding: 48px 60px;
  }
}
.Partner_rules {
  font-size: 16px;
  padding-inline-start: 16px;
  li {
    margin-bottom: 12px;
    color: #5d5d5d;
  }
}

.Learn_more {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 36px;
  padding: 4px 6px;
  background: red;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    rgb(233 176 255) 0.52%,
    rgb(255 163 223) 24.02%,
    rgb(155 173 254) 72.81%
  );
  color: white;

  a {
    color: white !important;
  }
}
@media screen and (max-width: 700px) {
  .Register_wrapper {
    flex-wrap: wrap;
    .Register_left,
    .Register_right {
      flex-basis: 100%;
    }
    .Register_left {
      border-right: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}
